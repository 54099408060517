import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import AboutUsBreadcrumb from "../components/AboutUs/AboutUsBreadcrumb";

function Termsofparticipation() {
  return (
    <div style={{ marginTop: "118px" }}>
      <Grid container spacing={2}>
        <Grid container item sm={12} md={12}>
          <AboutUsBreadcrumb />
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{
            marginLeft: { xs: 3, md: 20 },
            marginRight: { xs: 3, md: 20 },
            marginTop: 1,
          }}
        >
          <Box>
            <Typography
              sx={{
                fontWeight: { xs: "bold", md: "bold" },
                color: "#505252",
                marginBottom: 3,
              }}
              key="3"
              color="#00000"
              fontSize={{ xs: 17, md: 20 }}
            >
              eParticipation Portal: Pioneering Active Citizenry for a Stronger
              Sri Lanka
            </Typography>

            <Typography
              s
              sx={{
                fontWeight: { xs: "normal", md: "normal" },
                color: "#505252",
                textAlign: "justify",
              }}
              key="3"
              color="#00000"
              fontSize={{ xs: 15, md: 16 }}
            >
              Embarking on a groundbreaking initiative, eParticipation Portal is
              poised to redefine civic engagement in the Democratic Socialist
              Republic of Sri Lanka. More than just a platform, eParticipation
              Portal is a dedicated commitment to fostering a united society and
              enhancing Sri Lanka as a collective home for all its citizen
              <br /> <p />
              <b>Our Inception: A Fresh Approach to Connection</b>
              <br /> <p />
              As we launch this new initiative, it's essential to understand our
              roots. Born out of the vision to actively engage with citizens,
              eParticipation Portal emerges as a pioneer in building bridges
              between the people and the government. Our journey begins with a
              shift from the traditional notion of public feedback to a
              proactive approach of fostering connections. In this exciting
              venture, eParticipation Portal takes the lead as Sri Lanka's
              government-appointed e-engagement platform.
              <br /> <p />
              Our Mission: Listening, Engaging, Connecting
              <br /> <p />
              <b>
                At the core of eParticipation Portal's mission are three pivotal
                roles:
              </b>
              <br /> <p />
              1. Gauge Ground Sentiments:
              <br />
              <p />
              We are dedicated to tuning into the heartbeat of the nation. Your
              perspectives are invaluable, and eParticipation Portal is here to
              attentively listen. By understanding the sentiments of the people,
              we aim to spotlight key issues that hold significance for both the
              government and our fellow citizens.
              <br /> <p />
              2. Engage Citizens:
              <br /> <p />
              In our quest to reach every corner of Sri Lanka, we've established
              a diverse array of feedback channels. From conventional avenues
              like email and face-to-face dialogues to modern platforms such as
              eParticipation Portal WhatsApp Interactive Chat Group, Facebook,
              Instagram, LinkedIn, and TikTok, we offer a myriad of channels for
              you to actively engage with us. Collaborating with community
              organizations, grassroots movements, welfare groups, professional
              bodies, and special interest groups, we strive to ensure a
              comprehensive connection with citizens from all walks of life.
              <br /> <p />
              3. Promote Active Citizenry:
              <br /> <p />
              eParticipation Portal encourages you to be an active participant
              in shaping Sri Lanka's future. Through innovative public
              consultation exercises, we provide opportunities for you to
              contribute to the development of policies that will impact the
              trajectory of our nation.
              <br /> <p />
              Join us on this pioneering journey of connection and active
              citizenry. Together, let's build a stronger, more vibrant Sri
              Lanka."
            </Typography>
              <br />
            <hr />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default Termsofparticipation;
