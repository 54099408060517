import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import axios from "axios";
import { motion } from "framer-motion";
import Gallerybreadcrumb from '../components/gallery/gallerybreadcrumb';
import Gallerycard from '../components/gallery/gallerycard';

import { API_BASE_URL } from "../utils/constants";

export default function Photogallery() {
  const [open, setOpen] = React.useState(false);
  const [selectedGallery, setSelectedGallery] = useState(null);
  const [galleryImages, setGalleryImages] = useState([]);
  const [gallery, setGallery] = useState([]);

  const handleClickOpen = async (galleryId) => {
    try {
      const res = await axios.get(
        `${API_BASE_URL}/gallery/viewimages/${galleryId}`
      );
      setGalleryImages(res.data);

      setOpen(true);
    } catch (err) {
      console.log(err);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchAllContents = async () => {
      try {
        const res = await axios.get(`${API_BASE_URL}/gallery/viewgallery`);
        setGallery(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAllContents();
  }, []);

  return (
    <div>
      <Grid container spacing={2} style={{ marginTop: "102px" }}>
        <Grid container item sm={12} md={12}>
          <Gallerybreadcrumb />
        </Grid>
        {gallery.map((galleryItem, index) => (
          <Grid item key={index} lg={3} md={3} sm={6} xs={12}>
            <motion.div
              initial={{ x: -200, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 1.0, delay: index * 0.1 }}
            >
              <Box marginLeft={1} marginTop={1} marginRight={1.5}>
                <Gallerycard
                  Name={galleryItem.gallery_title}
                  date={galleryItem.date}
                  images={galleryItem.image_path}
                  short_description={galleryItem.short_description}
                  handleClickOpen={() =>
                    handleClickOpen(galleryItem.gallery_id)
                  }
                  handleClose={handleClose}
                  open={open}
                  selectedGallery={selectedGallery} // Pass selectedGallery as a prop
                  galleryImages={galleryImages} // Pass galleryImages as a prop
                />
              </Box>
            </motion.div>
          </Grid>
        ))}
      </Grid>
      <br />
    </div>
  );
}


