import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box } from "@mui/material";

// function handleClick(event) {
//   event.preventDefault();
//   console.info("You clicked a breadcrumb.");
// }

export default function PublicConsultationbreadcrumb() {
  const breadcrumbs = [
    <Link underline="hover" key="1" color="#FFFFFF" href="/">
      Home
    </Link>,
    <Typography key="3" color="#FFFFFF">
      Participate
    </Typography>,
    <Typography key="3" color="#FFFFFF">
      Public Consultations
    </Typography>,
  ];

  return (
    <>
      <Box sx={{ bgcolor: "#0178ba", width: "100%", height: "auto" }}>
        <Box marginLeft={3.5}>
          <Stack spacing={2}>
            <Box>
              <Breadcrumbs
                marginTop={2}
                separator={
                  <NavigateNextIcon
                    fontSize="small"
                    style={{ color: "#FFFFFF", fontFamily: "" }}
                  />
                }
                aria-label="breadcrumb"
              >
                {breadcrumbs}
              </Breadcrumbs>
              <Box>
                <Typography
                  sx={{ fontWeight: { xs: "normal", md: "bold" } }}
                  key="3"
                  color="#FFFFFF"
                  fontSize={{ xs: 30, md: 50 }}
                >
                  Public Consultations
                </Typography>
              </Box>
            </Box>
          </Stack>
        </Box>
      </Box>
    </>
  );
}
