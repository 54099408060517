import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActions } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { API_IMG_URL } from "../../utils/constants";

import Carousel from "react-material-ui-carousel";

export default function Gallerycard({
  handleClickOpen,
  Name,
  short_description,
  images,
  date,
  galleryImages,
  selectedGallery,
  handleClose,
  open = false, // Default value for open prop
}) {
  const [selectedImageIndex, setSelectedImageIndex] = React.useState(0);

  return (
    <div>
      <Card sx={{ display: "flex" }}>
        <CardMedia
          component="img"
          sx={{ width: 151, height: 194 }} // Adjust these styles as needed
          image={`${API_IMG_URL}/${images}`}
          alt="Live from space album cover"
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              sx={{ lineHeight: "1", minHeight: "4em", fontWeight: "bold" }}
            >
              {Name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {date}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {short_description}
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={handleClickOpen}
            >
              View Gallery
            </Button>
          </CardActions>
        </Box>
      </Card>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="body"
      >
        <Box sx={{ position: "relative" }}>
          <Carousel
            index={selectedImageIndex}
            autoPlay={false}
            interval={3000}
            navButtonsAlwaysVisible
            indicators={false}
            onChange={(index) => setSelectedImageIndex(index)}
          >
            {galleryImages &&
              galleryImages.map((photo, index) => (
                <Card key={index}>
                  <CardMedia
                    component="img"
                    maxWidth="400px"
                    height="400px"
                    image={`${API_IMG_URL}/${photo.image_path}`}
                    alt={`Image ${index + 1}`}
                  />
                </Card>
              ))}
          </Carousel>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{ position: "absolute", top: 0, right: 10, zIndex: 1 }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Dialog>
    </div>
  );
}
