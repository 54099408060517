import React from "react";
import { Grid, Link, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  FaDribbbleSquare,
  FaFacebookSquare,
  FaGithubSquare,
  FaInstagram,
  FaTwitterSquare,
} from "react-icons/fa";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";

// Define styled components for styling
const RootContainer = styled("div")({
  backgroundColor: "#0178ba",
  maxWidth: "100%",
  margin: "auto",
  padding: "40px",
  color: "#ffffff",
});

const IconContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  width: "75%",
  margin: "auto",
});

const SectionContainer = styled("div")({
  marginBottom: "24px",
});

const SignUpButton = styled(Button)({
  backgroundColor: "white",
  color: "#0178ba",
  "&:hover": {
    backgroundColor: "#0178ba",
    color: "white",
  },
});

const Footer = () => {
  return (
    <>
      <br />
      <RootContainer>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={4}>
            <Typography variant="h6" gutterBottom>
              <b>Sign up to receive email updates</b>
            </Typography>

            <Typography gutterBottom>
              <Link href="/newsletter">
                <SignUpButton variant="contained" endIcon={<SendIcon />}>
                  Sign Up
                </SignUpButton>
              </Link>
            </Typography>

            <Typography variant="body1" gutterBottom>
              +94 112 369 099
            </Typography>
            <IconContainer>
              <FaFacebookSquare size={30} />
              <FaGithubSquare size={30} />
              <FaInstagram size={30} />
              <FaTwitterSquare size={30} />
              <FaDribbbleSquare size={30} />
            </IconContainer>
          </Grid>

          {/* Right Grid */}
          <Grid item xs={12} lg={8} container spacing={4}>
            {/* Section 1 */}
            <Grid item xs={12} md={6} lg={3} component={SectionContainer}>
              <Typography variant="h6" gutterBottom>
                <b> Participate</b>
              </Typography>
              <ul style={{ listStyleType: "none", padding: 0 }}>
                <li>
                  <a
                    href="/consultations/events"
                    style={{
                      color: "white",
                      textDecoration: "none",
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    }}
                  >
                    Events
                  </a>
                </li>
                <li>
                  <a
                    href="/consultations/public-consultations"
                    style={{
                      color: "white",
                      textDecoration: "none",
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    }}
                  >
                    Public Consultations
                  </a>
                </li>
              </ul>
            </Grid>

            {/* Section 2 */}
            <Grid item xs={12} md={6} lg={3} component={SectionContainer}>
              <Typography variant="h6" gutterBottom>
                <b> About Us</b>
              </Typography>
              <ul style={{ listStyleType: "none", padding: 0 }}>
                <li>
                  <a
                    href="/aboutus"
                    style={{
                      color: "white",
                      textDecoration: "none",
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    }}
                  >
                    About ConnectSL
                  </a>
                </li>
                <li>
                  <a
                    href="/genaral-feedback"
                    style={{
                      color: "white",
                      textDecoration: "none",
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    }}
                  >
                    General Feedback
                  </a>
                </li>
                <li>
                  <a
                    href="/contact-us"
                    style={{
                      color: "white",
                      textDecoration: "none",
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    }}
                  >
                    Contact Us
                  </a>
                </li>
                <li>
                  <a
                    href="/Sitemap"
                    style={{
                      color: "white",
                      textDecoration: "none",
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    }}
                  >
                    SiteMap
                  </a>
                </li>
              </ul>
            </Grid>

            {/* Section 3 */}
            <Grid item xs={12} md={6} lg={3} component={SectionContainer}>
              <Typography variant="h6" gutterBottom>
                <b> User Policies</b>
              </Typography>
              <ul style={{ listStyleType: "none", padding: 0 }}>
                <li>
                  {" "}
                  <a
                    href="/termofuse"
                    style={{
                      color: "white",
                      textDecoration: "none",
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    }}
                  >
                    Terms of Use
                  </a>
                </li>
                <li>
                  {" "}
                  <a
                    href="/termofparticipation"
                    style={{
                      color: "white",
                      textDecoration: "none",
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    }}
                  >
                    Terms of Participation
                  </a>
                </li>
              </ul>
            </Grid>

            {/* Section 4 */}
            <Grid item xs={12} md={6} lg={3} component={SectionContainer}>
              <Typography variant="h6" gutterBottom>
                <b> For Media</b>
              </Typography>
              <ul style={{ listStyleType: "none", padding: 0 }}>
                <li>
                  {" "}
                  <a
                    href="/news-and-press-releases/latest-news"
                    style={{
                      color: "white",
                      textDecoration: "none",
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    }}
                  >
                    News & Press Releases
                  </a>
                </li>
              </ul>
            </Grid>
          </Grid>
        </Grid>
        <hr />

        <Typography style={{ textAlign: "center" }}>
          © 2024 ICTA | Last Updated 25 Mar 2024
        </Typography>
      </RootContainer>
    </>
  );
};

export default Footer;
