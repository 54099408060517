import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import axios from "axios";
import PublicConsultationsCard from "../components/publicConsultations/PublicConsultationsCard";
import PublicConsultationbreadcrumb from "../components/publicConsultations/PublicConsultationbreadcrumb";
import { API_BASE_URL } from "../utils/constants";
import moment from "moment";

export default function PublicConsultations() {
  const [cardData, setCardData] = useState([]);

  useEffect(() => {
    const fetchAllContents = async () => {
      try {
        const res = await axios.get(
          `${API_BASE_URL}/consultations/public-consultations`
        );
        setCardData(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAllContents();
  }, []);
  return (
    <div>
      <Grid item xs={12} marginTop={15}>
        <PublicConsultationbreadcrumb />
      </Grid>
      <br />
      <p />
      <Grid container>
        {cardData.map((content, index) => (
          <Grid item key={index} xs={12} sm={6} md={3}>
            <Box marginLeft={3} marginRight={3} marginTop={1}>
              <PublicConsultationsCard
                title={content.content_topic}
                subheader={`${moment(content.created_at).format(
                  "MMMM DD, YYYY"
                )}`}
                image={content.content_image}
                description={content.content_short_description}
                url={`/consultations/public-consultations/${content.content_url}`}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
      <br />
    </div>
  );
}
