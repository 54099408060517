import React, { useState, useEffect } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";
import { API_BASE_URL } from "../../utils/constants";

const SearchComponent = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSearch = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/allsearch/search-all`, {
        params: {
          query: searchQuery,
        },
      });
      console.log(response.data);
      setSearchResults(response.data);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };
  useEffect(() => {
    if (searchQuery.length > 0) {
      handleSearch();
    } else {
      setSearchResults([]);
    }
  }, [searchQuery]);

  const handleInputChange = (e) => {
    const userInput = e.target.value;
    // Regular expression to allow only letters, numbers, and spaces
    const regex = /^[a-zA-Z0-9\s]*$/;

    if (regex.test(userInput) || userInput === "") {
      setSearchQuery(userInput);
      setErrorMessage(""); // Clear error message if input is valid
    } else {
      setErrorMessage("Please enter only letters, numbers, or spaces");
    }
  };

  return (
    <div>
      <SearchBox>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
          value={searchQuery}
          onChange={handleInputChange}
        />
      </SearchBox>
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      <CustomList style={{ position: "absolute" }}>
        {searchResults.map((result) => (
          <CustomListItem key={result.url}>
            <a style={{color:"#0178ba",textDecoration:"none"}}
              href={
                
                result.tm === "eparticipation"
                  ? `/consultations/public-consultations/${result.url}`
                  : `/consultations/events/${result.url}`
              }
             
            >
              {result.topic} (Content)
            </a>
          </CustomListItem>
        ))}
      </CustomList>
    </div>
  );
};

const SearchBox = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const CustomList = styled(List)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: "5px",
  marginTop: theme.spacing(1),
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
}));

const CustomListItem = styled(ListItem)(({ theme }) => ({
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    cursor: "pointer",
  },
}));

export default SearchComponent;
