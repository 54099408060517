import React from "react";
import { Grid, Typography } from "@mui/material";
import SitemapBreadcrumb from "../components/Sitemap/SitemapBreadcrumb";

function Sitemap() {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ marginTop: "115px" }}>
          <SitemapBreadcrumb />
        </Grid>
        <Grid item xs={12} style={{ marginLeft: "27px" }}>
          <Typography variant="h6" style={{ color: "#0178ba", marginBottom: "10px" }}>
            Home
          </Typography>
          <Typography variant="h6" style={{ color: "#0178ba", marginBottom: "10px" }}>
            Participate
          </Typography>
          <ul >
            <li>
              <a href="/consultations/events" style={linkStyle}>
                Events
              </a>
            </li>
            <li>
              <a href="/consultations/public-consultations" style={linkStyle}>
                Public Consultations
              </a>
            </li>
          </ul>

          <Typography variant="h6" style={{ color: "#0178ba", marginTop: "20px", marginBottom: "10px" }}>
            Read
          </Typography>
          <ul >
            <li>
              <a href="/news-and-press-releases/latest-news" style={linkStyle}>
                News & Press Releases
              </a>
            </li>
          </ul>
          <Typography variant="h6" style={{ color: "#0178ba", marginBottom: "10px" }}>
          <a href="/viewgallery" style={linkStyle}>  Photo Gallery </a> 
          </Typography>
          

          <Typography variant="h6" style={{ color: "#0178ba", marginTop: "20px", marginBottom: "10px" }}>
            Who We Are
          </Typography>
          <ul >
            <li>
              <a href="/aboutus" style={linkStyle}>
                About Us
              </a>
            </li>
            <li>
              <a href="/faqs" style={linkStyle}>
                FAQs
              </a>
            </li>
            <li>
              <a href="/useful-links" style={linkStyle}>
                Useful Links
              </a>
            </li>
            <li>
              <a href="/genaral-feedback" style={linkStyle}>
                General Feedback
              </a>
            </li>
          </ul>
        </Grid>
      </Grid>
    </div>
  );
}

const linkStyle = {
  color: "#0178ba",
  textDecoration: "none",
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
};

export default Sitemap;
