import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import TermsofParticipationBreadcrumb from "../components/termsofparticipation/TermsofparticipationBreadcrumb";

function Termsofparticipation() {
  return (
    <div style={{ marginTop: "118px" }}>
      <Grid container spacing={2}>
        <Grid container item sm={12} md={12}>
          <TermsofParticipationBreadcrumb />
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{
            marginLeft: { xs: 3, md: 20 },
            marginRight: { xs: 3, md: 20 },
            marginTop: 1,
          }}
        >
          <Box>
            <Typography
              sx={{
                fontWeight: { xs: "bold", md: "bold" },
                color: "#505252",
                marginBottom: 3,
              }}
              key="3"
              color="#00000"
              fontSize={{ xs: 17, md: 20 }}
            >
              Terms and Conditions for Participation in eParticipation Portal
              Events (“Terms of Participation”) <hr />
            </Typography>

            <Typography
              sx={{
                fontWeight: { xs: "normal", md: "normal" },
                color: "#505252",
                textAlign: "justify",
              }}
              key="3"
              color="#00000"
              fontSize={{ xs: 15, md: 16 }}
            >
              eParticipation Portal is the Government's Feedback Unit in the
              Democratic Socialist Republic of Sri Lanka. We aim to gather and
              gauge ground sentiments, engage citizens, and promote active
              citizenry. Our face-to-face engagement platforms, including
              events, dialogues, public forums, and focus group discussions,
              provide channels for you to share your feedback with the
              Government. Feedback received will be recorded (whether through
              the use of audio/sound recordings or in writing) by eParticipation
              Portal and surfaced to relevant agencies. We also facilitate
              communication between citizens and agencies by proactively
              initiating discussions on various topics.
              <br /> <br />
              In exchange for your participation in eParticipation Portal’s
              events, you agree to be bound and abide by these Terms of
              Participation. Your participation in any engagement platform or
              event organized by eParticipation Portal (each an “Event”)
              constitutes your acceptance of these Terms of Participation.
              <br />
              <br />
              eParticipation Portal (representing the Government of the
              Democratic Socialist Republic of Sri Lanka) may add to, delete, or
              modify any or all of the terms and conditions in the Terms of
              Participation at any time without notice to you. The most current
              version of the Terms of Participation can be found at: [Insert
              updated URL for the Terms of Participation].
              <br /> <br />
              <b>1.</b> The Event is organized by eParticipation Portal.
              <br /> <br />
              <b>2.</b> All registrations/applications for participation in the
              Event are subject to the availability of seats, and any
              availability information is subject to change without notice.
              eParticipation Portal reserves the right to refuse any
              registrations/applications it receives for any reason. By
              submitting your registration/application, you agree to these Terms
              of Participation, as well as all other policies, terms, and
              conditions applicable to the Event and made known to you by
              eParticipation Portal (whether prior to, during, or after the
              Event).
              <br /> <br />
              <b>3.</b> eParticipation Portal reserves the right to postpone,
              cancel, interrupt, or terminate the Event, or make any other
              changes to the Event (including changes to venue, programs, times,
              or audience capacity), at any time, without prior notice and
              without any liability to you.
              <br /> <br />
              <b>4.</b> By submitting your application/registration for
              participation in the Event, you agree that:
              <ul style={{ listStyleType: "none" }}>
                <li>
                  <b>a.</b> Any and all personal data submitted by you in the
                  course of your application/registration for and participation
                  in the Event may be collected, used, and/or disclosed by
                  eParticipation Portal for various purposes, including to
                  communicate with you for purposes related to the Event, and
                  such other purposes eParticipation Portal may reasonably deem
                  appropriate, or in relation to which you have otherwise
                  provided your consent.
                </li>
                <br />
                <li>
                  <b>b.</b> eParticipation Portal may collect, use, disclose,
                  and share with its service providers your personal data,
                  including photographs or audio-video or other recordings of
                  you captured or obtained by or for eParticipation Portal
                  during or in relation to the Event (“Material”), for publicity
                  and/or use in advertisements across all media, including,
                  without limitation, in eParticipation Portal’s publications,
                  presentations, promotional materials on its websites, in its
                  original or edited format, whether to promote the Event or
                  otherwise, and without further notification, remuneration, or
                  compensation to you.
                </li>
                <br />
                <li>
                  <b>c.</b> The copyright and all other intellectual property
                  rights in and to all Material shall vest solely and absolutely
                  in eParticipation Portal without further compensation.
                </li>
                <br />
                <li>
                  <b>d.</b> Any personal information you provide will be
                  protected in accordance with eParticipation Portal's Privacy
                  Policy.
                </li>
              </ul>
              <b>5.</b> eParticipation Portal shall not be liable for any loss
              (including, without limitation, indirect or consequential loss),
              damage, personal injury, or death in connection with your
              registration/application for, or attendance at the Event, or any
              postponement, cancellation, or any other changes made by
              eParticipation Portal to the Event, except any liability that
              cannot be excluded by law (in which case that liability is limited
              to the minimum allowable by law).
              <br />
              <br />
              <b>6.</b> You may be asked to produce your valid photo ID
              (National Identity Card, Passport, or Driver’s License) for
              inspection before being granted admission to the Event.
              <br />
              <br />
              <b>7.</b> eParticipation Portal reserves the right to refuse
              admission to or remove from the Event any person(s) whose conduct
              is disorderly or inappropriate or who poses a threat to security
              or to the enjoyment of the Event by others.
              <br />
              <br />
              <b>8.</b> You shall not:
              <br />
              <ul style={{ listStyleType: "none" }}>
                <li>
                  <b>a.</b> Make any comments or remarks that may be unlawful,
                  harmful, threatening, abusive, harassing, tortious,
                  defamatory, vulgar, obscene, libelous, invasive of another's
                  privacy, hateful, or racially, religiously, ethnically, or
                  otherwise objectionable, irrelevant to eParticipation Portal's
                  purposes, or irrelevant to the existing/ongoing discussion(s).
                  eParticipation Portal has the sole discretion to decide if any
                  content falls within these said categories.
                </li>
                <br />
                <li>
                  <b>b.</b> Do anything that may be disruptive of other users’
                  engagement in the discussion, dialogue, or other exchange, or
                  otherwise act in a manner that negatively affects other
                  participants' user experience and/or ability to engage in
                  face-to-face or any other real-time exchanges.
                </li>
                <br />
                <li>
                  <b>c.</b> Threaten, stalk, or otherwise harass any other
                  participant or any person employed by eParticipation Portal;
                </li>
                <br />
                <li>
                  <b>d.</b> Restrict or inhibit any other participant from
                  accessing or participating in the Event.
                </li>
              </ul>
              <b>9.</b> If the Event, or any part thereof, is specifically
              stated to be held under the Chatham House Rule, you may use the
              information received by you during and for purposes of the Event,
              but neither the identity nor the affiliation of the speaker(s),
              nor that of any other participant, may be revealed.
              <br />
              <br />
              <b>10.</b> [The use of photographic equipment is allowed for
              private domestic purposes only.] All [other] recording and any
              transmission is prohibited, including (without limitation) taking
              photographs and recording of any data or information of or
              relating to the Event and any other participant.
              <br />
              <br />
              <b>11.</b> These terms and conditions are governed by the laws of
              the Democratic Socialist Republic of Sri Lanka, and you agree to
              submit to the non-exclusive jurisdiction of the Sri Lankan
              courts."
              <br />
              <br />
            </Typography>

            <hr />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default Termsofparticipation;
